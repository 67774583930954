import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import "./styles/side-panel.css";
import { Button } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import Login from "./pages/login";
import PrivateRoute from "./pages/private-router";
import Billing from "./pages/billing";
import Home from "./pages/home";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./config/firebase";
import LogoutIcon from "@mui/icons-material/Logout";
import { signOut } from "firebase/auth";
import Webhook from "./pages/webhook";
import WebhookIcon from "@mui/icons-material/Webhook";
import LiveTransfers from "./pages/live-transfers";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import CallIcon from "@mui/icons-material/Call";
import CallLogs from "./pages/call-logs";

function App() {
  const [isPanelOpen, setPanelOpen] = useState(true);
  const [user, loading] = useAuthState(auth);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className="App">
        {isPanelOpen && user != null && (
          <div className="side-panel">
            <center>
              <img
                src="https://3common.com/assets/3COM_Logo_Horiz_FullDark_sm.d03868d6.png"
                width={"100%"}
                style={{
                  borderRadius: "10%",
                }}
              />
            </center>
            <h1
              style={{
                color: "black",
                textAlign: "center",
                fontSize: "28px",
                fontWeight: "bold",
                fontFamily: "Arial",
                margin: "0 auto",
                padding: "20px",
              }}
            >
              3Common Agent Autodialer
            </h1>
            <br />
            <br />
            <ul>
              <li>
                <Button
                  component={Link}
                  to="/"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  <HomeIcon />
                  <div style={{ width: "10px" }} />
                  Home
                </Button>
              </li>
              <li>
                <Button
                  component={Link}
                  to="/call-logs"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  <CallIcon />
                  <div style={{ width: "10px" }} />
                  Call Logs
                </Button>
              </li>
              <li>
                <Button
                  component={Link}
                  to="/live-transfers"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  <PhoneForwardedIcon />
                  <div style={{ width: "10px" }} />
                  Live Transfers
                </Button>
              </li>
              <li>
                <Button
                  component={Link}
                  to="/billing"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  <AccountBalanceWalletIcon />
                  <div style={{ width: "10px" }} />
                  Billing
                </Button>
              </li>
              <li>
                <Button
                  component={Link}
                  to="/webhook"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  <WebhookIcon />
                  <div style={{ width: "10px" }} />
                  Webhooks
                </Button>
              </li>
              <li>
                <Button
                  // component={Link}
                  color="primary"
                  variant="contained"
                  onClick={() => signOut(auth)}
                  fullWidth
                >
                  <LogoutIcon />
                  <div style={{ width: "10px" }} />
                  Sign out
                </Button>
              </li>
            </ul>
          </div>
        )}

        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/billing"
            element={
              <PrivateRoute>
                <div className="content">
                  <Billing />
                </div>
              </PrivateRoute>
            }
          />
          <Route
            path="/webhook"
            element={
              <PrivateRoute>
                <div className="content">
                  <Webhook />
                </div>
              </PrivateRoute>
            }
          />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <div className="content">
                  <Home />
                </div>
              </PrivateRoute>
            }
          />
          <Route
            path="/call-logs"
            element={
              <PrivateRoute>
                <div className="content">
                  <CallLogs />
                </div>
              </PrivateRoute>
            }
          />
          <Route
            path="/live-transfers"
            element={
              <PrivateRoute>
                <div className="content">
                  <LiveTransfers />
                </div>
              </PrivateRoute>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
