import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  FormControlLabel,
  Switch,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  LinearProgress,
} from "@mui/material";
import "../styles/test-phone-calls.css"; // import the CSS file
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import { Link } from "react-router-dom";

function Home() {
  const [dashboardData, setDashboardData] = useState({
    totalLeads: 0,
    totalLiveTransfers: 0,
    totalPickUps: 0,
    totalFailedNumbers: 0,
    totalVoiceMails: 0,
    walletBalance: 0,
    queueActive: false,
    callingQueuePaused: false,
  });
  const [firstName, setFirstName] = useState("");
  const [agentData, setAgentData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [resumingCallsDialogOpen, setResumingCallsDialogOpen] = useState(false);
  const [resumingCallsForQueueDialogOpen, setResumingCallsForQueueDialogOpen] =
    useState(false);
  const [pausingCallsForQueueDialogOpen, setPausingCallsForQueueDialogOpen] =
    useState(false);
  const [pausingCallsDialogOpen, setPausingCallsDialogOpen] = useState(false);
  const [queueName, setQueueName] = useState("");

  const showResumingModal = () => {
    setResumingCallsDialogOpen(true);
  };

  const showResumingQueueModal = (currentQueueName) => {
    setQueueName(currentQueueName);
    setResumingCallsForQueueDialogOpen(true);
  };

  const showPausingModal = () => {
    setPausingCallsDialogOpen(true);
  };

  const showPausingQueueModal = (currentQueueName) => {
    setQueueName(currentQueueName);
    setPausingCallsForQueueDialogOpen(true);
  };

  const hideResumingModal = () => {
    setResumingCallsDialogOpen(false);
  };

  const hideResumingQueueModal = () => {
    setQueueName("");
    setResumingCallsForQueueDialogOpen(false);
  };

  const hidePausingModal = () => {
    setPausingCallsDialogOpen(false);
  };

  const hidePausingQueueModal = () => {
    setQueueName("");
    setPausingCallsForQueueDialogOpen(false);
  };

  const pauseCallsForAllQueues = async () => {
    // Code to call the cloud function
    return new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate async call
  };

  const pauseCallsForQueue = async () => {
    // Code to call the cloud function
    return new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate async call
  };

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div style={{ padding: "2rem" }}>
      <center>
        <Typography variant="h4" component="h1" gutterBottom>
          <strong>Welcome back!</strong>
        </Typography>
      </center>
      <Grid
        container
        spacing={3}
        style={{ marginTop: "2rem", justifyContent: "center" }}
      >
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">Total Leads</Typography>
              <Typography variant="h4">
                {numberWithCommas(dashboardData.totalLeads)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">Total Live Transfers</Typography>
              <Typography variant="h4">
                {numberWithCommas(dashboardData.totalLiveTransfers)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">Total Pick Ups</Typography>
              <Typography variant="h4">{dashboardData.totalPickUps}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={8}>
          <Card>
            <CardContent>
              <Typography variant="h6">Total Failed Numbers</Typography>
              <Typography variant="h4">
                {numberWithCommas(dashboardData.totalFailedNumbers)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">Wallet Balance</Typography>
              <Typography variant="h4">
                ${dashboardData.walletBalance.toFixed(2)}
              </Typography>
              <br />
              <Button
                variant="contained"
                color={"secondary"}
                component={Link}
                to="/billing"
              >
                Manage
              </Button>
            </CardContent>
          </Card>
        </Grid>
        <Box mt={7}>
          <center>
            <Grid item xs={12}>
              <h2>Calling Queue Statuses</h2>
              <Button
                variant="contained"
                color={
                  dashboardData.callingQueuePaused ? "primary" : "secondary"
                }
              >
                {dashboardData.callingQueuePaused ? "Start all" : "Pause all"}
                {dashboardData.callingQueuePaused ? (
                  <PlayArrowIcon />
                ) : (
                  <PauseIcon />
                )}
              </Button>
              <br />
              <br />
            </Grid>
          </center>
        </Box>
      </Grid>
      <Dialog open={resumingCallsDialogOpen}>
        <DialogContent>
          <DialogContentText>
            Resuming calls for all calling queues...
          </DialogContentText>
          <div style={{ height: "1.5em" }} />
          <LinearProgress />
        </DialogContent>
      </Dialog>
      <Dialog open={resumingCallsForQueueDialogOpen}>
        <DialogContent>
          <DialogContentText>Resuming {queueName} calls...</DialogContentText>
          <div style={{ height: "1.5em" }} />
          <LinearProgress />
        </DialogContent>
      </Dialog>
      <Dialog open={pausingCallsForQueueDialogOpen}>
        <DialogContent>
          <DialogContentText>Pausing {queueName} calls...</DialogContentText>
          <div style={{ height: "1.5em" }} />
          <LinearProgress />
        </DialogContent>
      </Dialog>
      <Dialog open={pausingCallsDialogOpen}>
        <DialogContent>
          <DialogContentText>
            Pausing calls for all calling queues...
          </DialogContentText>
          <div style={{ height: "1.5em" }} />
          <LinearProgress />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Home;
