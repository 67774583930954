// src/StripeSetup.js
import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe("pk_test_51LegOfH0DxDqRiYpJQh23IvMrJOvLYVrBXjR0gcZacH5RaQwRSpsZjB9GHAETsimFuIGMeOZfhoa0sJTVDkZE3TH002hMthmNZ");

const StripeSetup = ({ children }) => (
  <Elements stripe={stripePromise}>{children}</Elements>
);

export default StripeSetup;
