import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyBPXw-zGdxdQJ2-zFnUpSpr-OfQicq07a4",
  authDomain: "agent-auto-dialer-3common.firebaseapp.com",
  projectId: "agent-auto-dialer-3common",
  storageBucket: "agent-auto-dialer-3common.appspot.com",
  messagingSenderId: "605930420044",
  appId: "1:605930420044:web:ced2de95362093e8fe81e9",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const functions = getFunctions(app);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
