import React, { useState, useEffect } from "react";
import { auth, db } from "../config/firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import { Tooltip } from "react-tooltip";

import "../styles/webhook.css";

const Webhook = () => {
  const [agentId, setAgentId] = useState("");

  useEffect(() => {
    const fetchAgentId = async () => {
      const user = auth.currentUser;
      if (!user) return;

      const agentRef = collection(db, "agents");
      const agentQuery = query(agentRef, where("uid", "==", user.uid));
      const agentSnapshot = await getDocs(agentQuery);

      if (agentSnapshot.empty) return;

      const agentDoc = agentSnapshot.docs[0];
      setAgentId(agentDoc.id);
    };

    fetchAgentId();
  }, []);

  const copyToClipboard = (url) => {
    navigator.clipboard.writeText(url);
    alert("URL copied to clipboard!");
  };

  return (
    <>
      <h1 style={{ textAlign: "center" }}>Webhooks for Hubspot</h1>
      <div className="webhook" style={{ textAlign: "center" }}>
        <h3>3Common Hubspot Test</h3>
        <p
          data-tip="Click to copy"
          onClick={copyToClipboard(
            "https://3common.agentautodialer.com/server/test-3common-hubspot"
          )}
        >
          https://3common.agentautodialer.com/server/test-3common-hubspot
        </p>
        <Tooltip />
      </div>
    </>
  );
};

export default Webhook;
